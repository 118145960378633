import React, { Suspense, lazy } from 'react';
import { createRoot }from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ActaAuthProvider } from './acta_auth/provider';
import { createTheme, ThemeProvider, colors } from '@mui/material';
/*
import ModuleView from './app/modules/components/module';
import ModulesListView from './app/modules/components/list';
import RequestsListMineView from './app/requests/components/list_mine';
import RequestView from './app/requests/components/request';
import ServicesListView from './app/services/components/list';
import ServiceView from './app/services/components/service';
import PaymentResultView from './app/payments/components/payment_result';
import RequestsListView from './app/requests/components/list';
import LegalNotesiew from './app/policies/components/legal:notes';
import PrivacyPolicyView from './app/policies/components/provacy_policy';
import DummyHomepage from './app/components/dummy/homepage';
import DummyAmministrazione from './app/components/dummy/amministrazione';
import DummyNews from './app/components/dummy/news';
import DummyDocuments from './app/components/dummy/documenti';
import RequestsListWaitingProtocolView from './app/requests/components/list_waiting_protocol';
import DummyPayments from './app/components/dummy/payments';
*/


const ModuleView = lazy(() => import('./app/modules/components/module'));
const ModulesListView = lazy(() => import('./app/modules/components/list'));
const RequestsListMineView = lazy(() => import('./app/requests/components/list_mine'));
const RequestView = lazy(() => import('./app/requests/components/request'));
const ServicesListView = lazy(() => import('./app/services/components/services_list'));
const ServiceView = lazy(() => import('./app/services/components/service'));
const ServiceTopicsListView = lazy(() => import('./app/services/components/service_topics_list'));
const PaymentResultView = lazy(() => import('./app/payments/components/payment_result'));
const RequestsListView = lazy(() => import('./app/requests/components/list'));
const LegalNotesiew = lazy(() => import('./app/policies/components/legal:notes'));
const PrivacyPolicyView = lazy(() => import('./app/policies/components/provacy_policy'));
const RequestsListWaitingProtocolView = lazy(() => import('./app/requests/components/list_waiting_protocol'));
const DummyPayments = lazy(() => import('./app/components/dummy/payments'));



const container = document.getElementById('root');
const root = createRoot(container!);

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue[400],
      contrastText: colors.grey[50],
    },
    secondary: {
      main: colors.red[300],
      contrastText: colors.grey[50],
    },
    
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ActaAuthProvider>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<App />} >
                  {/*
                  <Route path="" element={<DummyHomepage />} />
                  <Route path="amministrazione" element={<DummyAmministrazione />} />
                  <Route path="news" element={<DummyNews />} />
                  <Route path="documenti" element={<DummyDocuments />} />
                  */}

                  <Route path="" element={<Navigate to="/services" />} />
                  <Route path="service">
                    <Route path=":slug" element={<ServiceView />} />
                  </Route>
                  <Route path="services">
                    <Route path="" element={<ServicesListView />} />
                    <Route path=":slug" element={<ServicesListView />} />
                  </Route>
                  <Route path="topics">
                    <Route path="" element={<ServiceTopicsListView />} />
                    <Route path=":slug" element={<ServicesListView />} />
                  </Route>
                  <Route path="module">
                    <Route path="new" element={<div>NEW MODULE</div>} />
                    <Route path=":moduleId" element={<ModuleView />} />
                  </Route>
                  <Route path="modules">
                    <Route path="" element={<ModulesListView />} />
                    <Route path=":categorySlug" element={<ModulesListView />} />
                  </Route>
                  <Route path="request">
                    <Route path="new/:requestId" element={<div>NEW request</div>} />
                    <Route path=":requestId/payments/:paymentOutcome" element={<RequestView />} />
                    <Route path=":requestId" element={<RequestView />} />
                  </Route>
                  <Route path="requests">
                    <Route path="" element={<RequestsListMineView />} />
                  </Route>
                  <Route path="manage">
                    <Route path="requests">
                      <Route path="" element={<RequestsListView />} />
                      <Route path="waiting-protocol" element={<RequestsListWaitingProtocolView />} />
                    </Route>
                  </Route>
                  <Route path="payments">
                    <Route path="result" element={<PaymentResultView />} />
                  </Route>

                  <Route path="demo-payments">
                    <Route path=":requestId/:paymentId" element={<DummyPayments />} />
                    <Route path=":requestId" element={<DummyPayments />} />
                  </Route>


                  <Route path="note-legali" element={<LegalNotesiew />} />
                  <Route path="privacy-policy" element={<PrivacyPolicyView />} />
                </Route>
              </Routes>
            </Suspense>
          </ThemeProvider>
      </ActaAuthProvider>
     </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();