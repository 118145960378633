import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, IconButton, MenuList, Skeleton, styled, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import { useContext, useState } from 'react';
import ActaAuthContext from '../provider';
import ActaAuthUtils from '../utils';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Col, Dropdown, DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Row, UncontrolledDropdown } from 'design-react-kit';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  
const NavBarMenu = () => {
    const { user, isLoading, clearSelectedRole }    = useContext(ActaAuthContext);
    const [ isMenuOpen, _setIsMenuOpen ]    = useState(false);
    const navigate                  = useNavigate();
    
    const handleToggle = () => {
        _setIsMenuOpen(!isMenuOpen);
    };
    const handleClose = () => {
        _setIsMenuOpen(false);
    };
    
    const handleLogin = () => {
        ActaAuthUtils.login();
    };
    const handleLogout = () => {
        ActaAuthUtils.logout();
    };


    if(isLoading){
        return (
            <div>
                <Button
                    disabled
                >
                    <Skeleton variant="circular" width={40} height={40} />
                </Button>
            </div>
        );
    }

    if(user.isLoggedIn){

        return (
            <Row>
                <Dropdown nav tag="div" isOpen={isMenuOpen} toggle={() => _setIsMenuOpen(!isMenuOpen)} >
                    <DropdownToggle caret nav>
                        { user.firstName } { user.lastName }
                        { user.isManager && (
                            <> (operatore)</>
                        )}
                        <Icon className="d-none d-lg-block" color="icon-white" icon="it-user" />
                        <Icon className="d-none d-lg-block" color="icon-white" icon="it-expand" />
                    </DropdownToggle>
                    <DropdownMenu style={{ width: 300 }} >
                        <Row>
                            <Col size="12">
                                <LinkList>
                                    <LinkListItem header style={{ color:"#888888" }}>
                                        MENU
                                    </LinkListItem> 
                                    <LinkListItem>
                                        <a href="#" onClick={() => { handleClose(); navigate("/requests/"); }} style={{ color:"#888888" }}>Pratiche inviate</a>
                                    </LinkListItem>
                                    <Divider sx={{ my: 1, mb: 2 }} /> 

                                    { user.isManager && (
                                        <>
                                            <LinkListItem header style={{ color:"#888888" }}>
                                                MENU OPERATORE
                                            </LinkListItem> 
                                            <LinkListItem>
                                                <a href="#" onClick={() => { handleClose(); navigate("/manage/requests/"); }} style={{ color:"#888888" }}>Pratiche ricevute</a>
                                            </LinkListItem>
                                            <LinkListItem>
                                                <a href="#" onClick={() => { handleClose(); navigate("/manage/requests/waiting-protocol"); }} style={{ color:"#888888" }}>Pratiche da protocollare</a>
                                            </LinkListItem>
                                            <Divider sx={{ my: 1, mb: 2 }} /> 
                                        </>
                                    )}

                                    { (user.isManager || user.role != user.selectedRole) && (
                                        <>
                                            <LinkListItem>
                                                <a href="#" onClick={() => { handleClose(); clearSelectedRole(); }} style={{ color:"#888888" }}>Modifica ruolo</a>
                                            </LinkListItem>
                                            <Divider sx={{ my: 1, mb: 2 }} /> 
                                        </>
                                    )}

                                    <LinkListItem>
                                        <Button  color='primary' size='sm' onClick={handleLogout} block>
                                            ESCI
                                        </Button>
                                    </LinkListItem>
                                </LinkList>
                            </Col>
                        </Row>
                    </DropdownMenu>
                </Dropdown>
            </Row>
        );

    }else{

        return (
            <Button  color='primary' size='sm' onClick={handleLogin}>
                ACCEDI
            </Button>
        );
    }
}
export default NavBarMenu;