import { createContext, useEffect, useState } from 'react'
import http from '../services/http';
import { ActaUser } from './models/user';
import ActaAuthUtils from './utils';
import { useNavigate , useLocation } from "react-router-dom";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'design-react-kit';
import Loading from '../app/components/loading';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';

const defaultData = {
  user: new ActaUser({}),
  isLoading: true,
  clearSelectedRole: ()=>{},
};

const ActaAuthContext = createContext(defaultData);

type ActaAuthProviderProps = React.PropsWithChildren<{}>;

export const ActaAuthProvider = ({ children, ...other }: ActaAuthProviderProps) => {

  const [user, setUser]           = useState(defaultData.user);
  const [isLoading, setIsLoading] = useState(defaultData.isLoading);
  const location                  = useLocation();
  const navigate                  = useNavigate();

  const [isModalRoleOpen, setModalRoleOpen]   = useState(true);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {

    let queryParams = new URLSearchParams(location.search);
    /*
    let saveTokenIsNeeded = false;

    if(queryParams.get(ActaAuthUtils.accessQueryTokenKey)){
      localStorage.setItem(ActaAuthUtils.accessTokenKey, queryParams.get(ActaAuthUtils.accessQueryTokenKey)!);
      queryParams.delete(ActaAuthUtils.accessQueryTokenKey);
      saveTokenIsNeeded = true;
    }
    if(queryParams.get(ActaAuthUtils.refreshQueryTokenKey)){
      localStorage.setItem(ActaAuthUtils.refreshTokenKey, queryParams.get(ActaAuthUtils.refreshQueryTokenKey)!);
      queryParams.delete(ActaAuthUtils.refreshQueryTokenKey);
      saveTokenIsNeeded = true;
    }
    if(saveTokenIsNeeded){
      await new Promise(resolve => setTimeout(resolve, 100));
      navigate(location.pathname + queryParams.toString(), {
          replace: true,
      });
    }
    */

    if(queryParams.get(ActaAuthUtils.accessQueryTokenKey) && queryParams.get(ActaAuthUtils.refreshQueryTokenKey)){

      ActaAuthUtils.setToken({
        "access": queryParams.get(ActaAuthUtils.accessQueryTokenKey)!,
        "refresh": queryParams.get(ActaAuthUtils.refreshQueryTokenKey)!,
      });

      queryParams.delete(ActaAuthUtils.accessQueryTokenKey);
      queryParams.delete(ActaAuthUtils.refreshQueryTokenKey);

      await new Promise(resolve => setTimeout(resolve, 100));
      navigate(location.pathname + queryParams.toString(), {
          replace: true,
      });
    }
    if(queryParams.get(ActaAuthUtils.logoutQueryKey) && queryParams.get(ActaAuthUtils.logoutQueryKey) == "true"){

      ActaAuthUtils.clearToken();
      queryParams.delete(ActaAuthUtils.logoutQueryKey);

      await new Promise(resolve => setTimeout(resolve, 100));
      navigate(location.pathname + queryParams.toString(), {
          replace: true,
      });
    }



    if(ActaAuthUtils.hasTokens()){
      http.get("/user/", { headers: {
        "Authorization": "Bearer " + ActaAuthUtils.getAccessToken(),
      }})
        .then((result:any) => setUser(new ActaUser(result.data)))
        .catch((error:any) => setUser(defaultData.user))
        .finally(() => setIsLoading(false));
    }else{
      setIsLoading(false);
    }
  };

  const setSelectedRole = (role: string) => {
    ActaAuthUtils.setSelectedRole(role);
    fetchUser();
  }

  const clearSelectedRole = () => {
    ActaAuthUtils.clearSelectedRole();
    fetchUser();
  }

  return (
    <ActaAuthContext.Provider value={{
      user, isLoading, clearSelectedRole
    }} {...other}>
      {children}


      <Modal isOpen={user.isLoggedIn && user.selectedRole == undefined} size="l" labelledBy='role-select'>
          <ModalHeader id='role-select'>
            Selezione la modalità di utilizzo
          </ModalHeader>
          {isLoading ? (
            <Loading />
          ) : (
              <>
                  <ModalBody>
                      <div>
                        Il tuo account possiede privilegi amministrativi, seleziona la modalità con cui si desidera utilizzale il sito.
                        <Container className='my-4'>
                          <Row></Row>
                          <Row>
                            <Col>
                              <Button outline color='primary' style={{ width: "100%", height: 150 }} onClick={() => setSelectedRole("user")}>
                                <PersonIcon style={{ fontSize: 100 }} /><br/>
                                UTENTE
                              </Button>
                            </Col>
                            <Col>
                              <Button outline color='primary' style={{ width: "100%", height: 150 }} onClick={() => setSelectedRole(user.role)}>
                                <BadgeIcon style={{ fontSize: 100 }} /><br/>
                                OPERATORE
                              </Button>
                            </Col>
                          </Row>
                          <Row></Row>
                        </Container>
                      </div>
                  </ModalBody>
                  <ModalFooter>
                  </ModalFooter>
              </>
          )}
      </Modal>
      
    </ActaAuthContext.Provider>
  );
}
export default ActaAuthContext;