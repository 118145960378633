
import { useContext } from 'react';

import { Headers, Header, HeaderContent, HeaderBrand, HeaderToggler, Icon, Collapse, LinkList, LinkListItem, HeaderRightZone, UncontrolledDropdown, DropdownToggle, DropdownMenu, Row, Col, HeaderSocialsZone, HeaderSearch, Nav, NavItem, NavLink } from 'design-react-kit';

import ActaAuthContext from '../provider'
import { alpha, InputBase, styled } from '@mui/material';
import NavBarMenu from './navbar_menu';
import env from "../../env";



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.10),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    color: theme.palette.common.black,
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const SlimHeader = (props:any) => {
    return (
        <Header
          theme=""
          type="slim"
        >
          <HeaderContent>
            <HeaderBrand>
              Regione Abruzzo
            </HeaderBrand>
            <HeaderRightZone>
              <UncontrolledDropdown nav tag="div">
                <DropdownToggle caret nav>
                  ITA
                  <Icon
                    className="d-none d-lg-block"
                    color="icon-white"
                    icon="it-expand"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <Row>
                    <Col size="12">
                      <LinkList>
                        <LinkListItem
                          href="#"
                        >
                          <span>
                            ITA
                          </span>
                        </LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavBarMenu />
            </HeaderRightZone>
          </HeaderContent>
        </Header>
    );
}

const CenterHeader = (props:any) => {
    return (
        <Header
          theme=""
          type="center"
        >
          <HeaderContent>
            <HeaderBrand iconName="/images/comune_actia_stemma.svg" href={ env.ACTAGOV_BASE_URL } >
              <h2>
                Comune di Actia
              </h2>
              <h3>
                <strong>Sportello Digitale</strong>
              </h3>
            </HeaderBrand>
            <HeaderRightZone>
              <HeaderSocialsZone label="Seguici su">
                <ul>
                  <li>
                    <a
                      aria-label="Facebook"
                      href="#"
                      target="_blank"
                    >
                      <Icon icon="it-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Github"
                      href="#"
                      target="_blank"
                    >
                      <Icon icon="it-github" />
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Twitter"
                      href="#"
                      target="_blank"
                    >
                      <Icon icon="it-twitter" />
                    </a>
                  </li>
                </ul>
              </HeaderSocialsZone>
              <HeaderSearch
                iconName="it-search"
                label="Cerca"
              />
            </HeaderRightZone>
          </HeaderContent>
        </Header>
    );
}

const NavHeader = (props:any) => {
    return (
        <Header
          theme=""
          type="navbar"
        >
          <HeaderContent
            expand="lg"
            megamenu
          >
            <HeaderToggler
              aria-controls="nav1"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={function noRefCheck(){}}
            >
              <Icon icon="it-burger" />
            </HeaderToggler>
            <Collapse
              header
              navbar
              onOverlayClick={function noRefCheck(){}}
            >
              <div className="menu-wrapper" >
                <Nav navbar data-element="main-navigation">
                  <NavItem>
                    <NavLink href={ env.ACTAGOV_BASE_URL + "amministrazione/" }>Amministrazione</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={ env.ACTAGOV_BASE_URL + "novita/" }>Novità</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/services" data-element="all-services">Servizi</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={ env.ACTAGOV_BASE_URL + "vivere-il-comune/" }>Vivere il Comune</NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Collapse>
          </HeaderContent>
        </Header>
    );
}

const NavBar = (props:any) => {
    const { user, isLoading } = useContext(ActaAuthContext)

    return (        
        <Headers>
            <SlimHeader theme="" />
                <div className="it-nav-wrapper">
                    <CenterHeader theme="" />                    
                    <NavHeader theme="" />
                </div>
                <section className="useful-links-section">
                  <div className="section bg-grey-card p-2 py-3">
                    <div className="container">
                      <div style={{ display: "flex", flexDirection: "row"}}>
                        <div style={{ flexGrow: 1 }}>
                          <h6>Lo SPORTELLO DIGITALE è un prodotto certificato AgID e CSA STAR</h6>
                          <div><a className="read-more" href="https://catalogocloud.agid.gov.it/service/2178" target="_blank">Vai alla scheda sul Cloud Marketplace AgID</a></div>
                        </div>
                        <a href='https://catalogocloud.agid.gov.it/service/2178' target="_blank">
                          <img height={50} src="/images/logo-agid.png" />
                        </a>
                        <a href='https://cloudsecurityalliance.org/star/registry/actainfo-di-addari-igino-s-a-s/services/sportello-digitale/' target="_blank" style={{ marginTop: -10, marginBottom: -10, marginLeft: 20 }}>
                          <img src="/images/LOGO-CSA-STAR-Level-1.png" height={"70"} className="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
        </Headers>
    );
}
export default NavBar;

/*
NavBar.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};
*/