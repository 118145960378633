
import { Container, Row, Col, Icon, LinkList, LinkListItem, Form, Label, Input, Button } from 'design-react-kit';
import { useNavigate } from 'react-router-dom';
import env from "../../env";


const Footer = (props:any) => {
  const navigate = useNavigate();

    return (        
        <footer className="it-footer">
          <div className="it-footer-main">
            <Container>
              <section>
                <Row className="clearfix">
                  <Col sm={12}>
                    <div className="it-brand-wrapper">
                      <a
                        className=""
                        href={ env.ACTAGOV_BASE_URL }
                      >
                        <img src="/images/comune_actia_stemma.svg" height={50} className="mr-2" />
                        <div className="it-brand-text">
                          <h2>
                            Comune di Actia
                          </h2>
                          <h3 className="d-none d-md-block">
                            <strong>Sportello Digitale</strong>
                          </h3>
                        </div>
                      </a>
                    </div>
                  </Col>
                </Row>
              </section>
              <section>
                <Row>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                    sm={6}
                  >
                    <h4>
                      <a
                        href="/amministrazione"
                        title="Vai alla pagina: Amministrazione"
                      >
                        Amministrazione
                      </a>
                    </h4>
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Giunta e consiglio"
                      >
                        Giunta e consiglio
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Aree di competenza"
                      >
                        Aree di competenza
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Dipendenti"
                      >
                        Dipendenti
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Luoghi"
                      >
                        Luoghi
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Associazioni e società partecipate"
                      >
                        Associazioni e società partecipate
                      </LinkListItem>
                    </LinkList>
                  </Col>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                    sm={6}
                  >
                    <h4>
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Servizi"
                      >
                        Servizi
                      </a>
                    </h4>
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Pagamenti"
                      >
                        Pagamenti
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Sostegno"
                      >
                        Sostegno
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Domande e iscrizioni"
                      >
                        Domande e iscrizioni
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Segnalazioni"
                      >
                        Segnalazioni
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Autorizzazioni e concessioni"
                      >
                        Autorizzazioni e concessioni
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Certificati e dichiarazioni"
                      >
                        Certificati e dichiarazioni
                      </LinkListItem>
                    </LinkList>
                  </Col>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                    sm={6}
                  >
                    <h4>
                      <a
                        href="/news"
                        title="Vai alla pagina: Novità"
                      >
                        Novità
                      </a>
                    </h4>
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href="/news"
                        title="Vai alla pagina: Notizie"
                      >
                        Notizie
                      </LinkListItem>
                      <LinkListItem
                        href="/eventi"
                        title="Vai alla pagina: Eventi"
                      >
                        Eventi
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Comunicati Stampa"
                      >
                        Comunicati Stampa
                      </LinkListItem>
                    </LinkList>
                  </Col>
                  <Col
                    lg={3}
                    md={3}
                    sm={6}
                  >
                    <h4>
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Documenti"
                      >
                        Documenti
                      </a>
                    </h4>
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Progetti e attività"
                      >
                        Progetti e attività
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Delibere, determine e ordinanze"
                      >
                        Delibere, determine e ordinanze
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Bandi"
                      >
                        Bandi
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Concorsi"
                      >
                        Concorsi
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Albo pretorio"
                      >
                        Albo pretorio
                      </LinkListItem>
                    </LinkList>
                  </Col>
                </Row>
              </section>
              <section className="py-4 border-white border-top">
                <Row>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                  >
                    <h4>
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Amministrazione"
                      >
                        Amministrazione trasparente
                      </a>
                    </h4>
                    <p>
                      I dati personali pubblicati sono riutilizzabili solo alle condizioni previste dalla direttiva comunitaria 2003/98/CE e dal d.lgs. 36/2006
                    </p>
                  </Col>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                  >
                    <h4>
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Contatti"
                      >
                        Contatti
                      </a>
                    </h4>
                    <p>
                      <strong>
                        Comune di Actia
                      </strong>
                      
                      <br />Via nazionale, 23 64026 Roseto degli Abruzzi
                      <br />P.IVA: 0078945612
                      <br />C.F.: 0012345678
                    </p>
                    {/*
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Posta Elettronica Certificata"
                      >
                        Posta Elettronica Certificata
                      </LinkListItem>
                      <LinkListItem
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: URP - Ufficio Relazioni con il Pubblico"
                      >
                        URP - Ufficio Relazioni con il Pubblico
                      </LinkListItem>
                    </LinkList>
                    */}
                  </Col>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                  >
                    <h4>
                      &nbsp;
                    </h4>
                    <p>
                      Email: email@ordinaria.it
                      <br/>PEC: email@pec.it
                      <br/>
                      <br/>Telefono: 0555 1234
                      <br/>Telefono 2: 0555 5678
                    </p>
                  </Col>
                  <Col
                    className="pb-2"
                    lg={3}
                    md={3}
                  >
                    <h4>
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Vai alla pagina: Seguici su"
                      >
                        Seguici su
                      </a>
                    </h4>
                    <ul className="list-inline text-left social">
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href={ env.ACTAGOV_BASE_URL }
                          target="_blank"
                        >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-designers-italia"
                            size="sm"
                          />
                          <span className="sr-only">
                            Designers Italia
                          </span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href={ env.ACTAGOV_BASE_URL }
                          target="_blank"
                        >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-twitter"
                            size="sm"
                          />
                          <span className="sr-only">
                            Twitter
                          </span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href={ env.ACTAGOV_BASE_URL }
                          target="_blank"
                        >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-medium"
                            size="sm"
                          />
                          <span className="sr-only">
                            Medium
                          </span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href={ env.ACTAGOV_BASE_URL }
                          target="_blank"
                        >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-behance"
                            size="sm"
                          />
                          <span className="sr-only">
                            Behance
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div style={{ textAlign: "left" }}>
                      <img src="/images/logo-agid-light.png" height={30} />
                      <br/><br/>
                      <img src="/images/LOGO-CSA-STAR-Level-1.png" height={"100"} className="" />
                    </div>
                  </Col>
                </Row>
              </section>
            </Container>
          </div>
          <div className="it-footer-small-prints clearfix">
            <Container>
              <h3 className="sr-only">
                Sezione Link Utili
              </h3>
              <Row>
                <Col lg="9">
                  <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                    <li className="list-inline-item">
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Note Legali"
                      >
                        Media policy
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={ env.ACTAGOV_BASE_URL }  onClick={() => { navigate("/note-legali"); }}
                        title="Note Legali"
                      >
                        Note legali
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={ env.ACTAGOV_BASE_URL }  onClick={() => { navigate("/privacy-policy/"); }}
                        title="Privacy-Cookies"
                      >
                        Privacy policy
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={ env.ACTAGOV_BASE_URL }
                        title="Mappa del sito"
                      >
                        Mappa del sito
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col lg="3">
                    <p className="text-right mb-0 text-white poweredby">Powered by <img src="../../../images/logo_actainfo.png" width="20" /> <a href="https://www.actainfo.it" target="_blank"><strong>ACTAINFO</strong></a></p>
                </Col>
              </Row>              
            </Container>
          </div>
        </footer>
    );
}
export default Footer;