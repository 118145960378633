import jwt_decode from "jwt-decode";
import env from "../env";

const ActaAuthUtils = () => {

    const accessTokenKey = "acta-jwt-access";
    const accessQueryTokenKey = "acta-jwt-access";
    const refreshTokenKey = "acta-jwt-refresh";
    const refreshQueryTokenKey = "acta-jwt-refresh";
    const logoutQueryKey = "acta-jwt-logout";
    const selectedRoleKey = "acta-selected-role";

    const loginEndpoint =   env.SSO_BASE_URL + "actaauth/actainfo/";
    const logoutEndpoint =  env.SSO_BASE_URL + "actaauth/logout/";
    const tokenEndpoint =   env.SSO_BASE_URL + "actaauth/token/";

    const provider = "actainfo"
    
    const setToken = (tokenObj:any) => {
        if (tokenObj.access) {
        localStorage.setItem(accessTokenKey, tokenObj.access);
        }
        if (tokenObj.refresh) {
        localStorage.setItem(refreshTokenKey, tokenObj.refresh);
        }
    }
    const hasTokens = () => {
        return getAccessToken() != null || getRefreshToken() != null;
    }
    const getAccessToken = () => {
        return localStorage.getItem(accessTokenKey);
    }
    const getRefreshToken = () =>{
        return localStorage.getItem(refreshTokenKey);
    }
    const getTokenValidity = (tokenObj:string) => {
        
        const decodedToken:any = jwt_decode(tokenObj);
        const dateNow = new Date();
        const timeStamp = dateNow.getTime() / 1000;
        
        if (decodedToken.exp < timeStamp) {
            return false;
        }
        
        return true;
    }
    const getRefreshTokenValidity = () => {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
            return getTokenValidity(refreshToken);
        }
        return null;
    }

    const clearToken = () => {
        localStorage.removeItem(accessTokenKey);
        localStorage.removeItem(refreshTokenKey);
        clearSelectedRole();
    }


    const login = () =>{
        var href = encodeURIComponent(window.location.href);
        window.location.href = loginEndpoint + "?provider=" + encodeURIComponent(provider) + "&return=" + href; 
    }
    const logout = () =>{
        //var href = encodeURIComponent(window.location.href);
        var href = env.FRONTEND_BASE_URL + "services";
        window.location.href = logoutEndpoint + "?return=" + href; 
    }

    const setSelectedRole = (role:string) => {
        localStorage.setItem(selectedRoleKey, role);
    }
    const getSelectedRole = () => {
        return localStorage.getItem(selectedRoleKey);
    }
    const clearSelectedRole = () => {
        localStorage.removeItem(selectedRoleKey);
    }

    return {
        accessTokenKey,
        accessQueryTokenKey,
        refreshTokenKey,
        refreshQueryTokenKey,
        logoutQueryKey,

        loginEndpoint,
        logoutEndpoint,
        tokenEndpoint,

        setToken,
        hasTokens,
        getAccessToken,
        getRefreshToken,
        getTokenValidity,
        getRefreshTokenValidity,
        clearToken,

        setSelectedRole,
        getSelectedRole,
        clearSelectedRole,

        login,
        logout,
    };

}
export default ActaAuthUtils()
