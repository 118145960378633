import { useContext } from 'react';
import './App.css';
import Box from '@mui/material/Box';

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import { Container } from 'design-react-kit';

import NavBar from './acta_auth/components/navbar';
import Footer from './acta_auth/components/footer';
import { Paper, Typography } from '@mui/material';
import ActaAuthContext from './acta_auth/provider';
import { Outlet} from 'react-router-dom';
import api from './services/api';



const App = () => {
  const { user, isLoading } = useContext(ActaAuthContext);

  return (
    <div className="App">      
      <NavBar />
      <Container fluid>
          <Outlet />
      </Container>
      <Footer />     
    </div>
  );

}
export default App;
