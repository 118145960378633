import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";

import ActaAuthUtils from '../acta_auth/utils';
import env from "../env";


const http = axios.create({
  baseURL: env.BACKEND_BASE_URL + 'api/',
  //baseURL: "http://192.168.222.8:8081/api",
  headers: {
    "Content-type": "application/json",
    //"Authorization": "Bearer " + ActaAuthUtils.getAccessToken(),
  }
});

http.interceptors.request.use(
    (config) => {
        const token = ActaAuthUtils.getAccessToken();
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers!.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);
  
http.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        const valid = ActaAuthUtils.getRefreshTokenValidity();
        
        if (!valid) {
            ActaAuthUtils.clearToken();
            return null;
        }
        if (error?.response?.status === 401 && !originalRequest.retry) {
            originalRequest.retry = true;
            
            return http({
                url: ActaAuthUtils.tokenEndpoint + "refresh/",
                method: "post",
                data: {
                    refresh: ActaAuthUtils.getRefreshToken(),
                },
            }).then((res) => {

                if (res.status === 200) {
                    ActaAuthUtils.setToken(res.data);
        
                    http.defaults.headers.common.Authorization = `Bearer ${ActaAuthUtils.getAccessToken()}`;
        
                    return http(originalRequest);
                }
                return null;
            });
        }
        return Promise.reject(error);
    },
);

export default http

/*
const useActaAuthApi = (config:any) => {
    const {
        axiosInstance,
        method,
        url,
        requestConfig = {}
    } = config;

    const [response, setResponse]   = useState([]);
    const [error, setRError]        = useState('');
    const [loading, setLoading]     = useState(true);


    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                const res = await axiosInstance[method.toLowerCase()](url, {
                    ...requestConfig,
                    signal: controller.signal,
                });
                setResponse(res.data);
            } catch(err:any) {
                setRError(err.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();

        return () => controller.abort();
    }, []);

    return [response, error, loading];
}
export { useActaAuthApi }
*/