import ActaAuthUtils from '../utils';

export class ActaUser {

    id: string;
    username: string;
    firstName: string;
    lastName: string;

    role: string;
    selectedRole?: string;
    //serviceCategory: any;

    data:any;

    get isManager():boolean {

        let testingRole = this.selectedRole ?? this.role;

        if(testingRole == "super_admin")  return true;
        if(testingRole == "admin")        return true;
        if(testingRole == "manager")      return true;
        return false;
    }

    get fullName():string {
        return this.firstName + ' ' + this.lastName;
    }
    get firstNameInitials():string {
        var parts = this.firstName.split(' ');
        return parts.map(part => part[0]).join('');
    }
    get isLoggedIn():boolean {
       return this.username !== undefined;
    }

    constructor(userData: any){
        this.data = userData;

        this.id = userData.id ;
        this.username = userData.username;
        this.firstName = userData.first_name;
        this.lastName = userData.last_name;

        this.role = userData.role;

        let selectdRole = ActaAuthUtils.getSelectedRole();
        this.selectedRole = selectdRole ?? (this.isManager ? undefined : this.role);
        //this.serviceCategory = userData.service_category;
    }
}